import sayHello from './lib/sayHello.js';
import Vue from 'vue';
import Vuesax from 'vuesax';


Vue.use(Vuesax);

sayHello();

$('#btn-closed-cookie').click(function() {
  $('#cookie-up-wr').toggle('slow');
});

new Vue({
  el: '#mobile-menu',
  data:() => ({
    active:false,
  })
});

